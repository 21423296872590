import { useState, useEffect, useDebugValue } from 'react'
import './App.css'

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import green from '@mui/material/colors/green'
import yellow from '@mui/material/colors/yellow'
import CircularProgress from '@mui/material/CircularProgress'

import { useLogin } from './LoginContext'
import SignOut from './SignOut'
import SignInSignUp from './SignInSignUp'
import LoginParent from './LoginParent'
import CustomerSupportFab from './CustomerSupportFab'
const willRedirect = new URLSearchParams(window.location.search).get('returnTo')?.toLowerCase()

const theme = createTheme({
  palette: {
    primary: green,
    secondary: yellow,
  },
})

const DisplayComponent = ({ willRedirect, loggedIn, returnTo, setWindowTitle, loginPending, setLoginPending }) => {
  return (
    <>
      {loggedIn ?
        <SignOut redirect={returnTo} willRedirect={willRedirect} setWindowTitle={setWindowTitle} loginPending={loginPending} setLoginPending={setLoginPending} /> :
        <SignInSignUp setWindowTitle={setWindowTitle} loginPending={loginPending} setLoginPending={setLoginPending} />}
    </>
  )
}

function App() {
  const [loginState] = useLogin()
  const [loggedIn, setLoggedIn] = useState(false)
  const [windowTitle, setWindowTitle] = useState('')
  const [loginPending, setLoginPending] = useState(false)

  useDebugValue(loginState.loginReady, loginState.loginReady ? 'Login Ready' : 'Waiting on Login State')

  useEffect(() => {
    if (loginState.loginReady) {
      setLoggedIn(!!loginState?.user)
      setLoginPending(false)  // Is this the right place?
    } else {
      setWindowTitle('')
    }
  }, [loginState])

  return (
    <div className='App'>
      <CustomerSupportFab />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LoginParent title={windowTitle}>
            {loginState && loginState.loginReady ?
              <DisplayComponent willRedirect={willRedirect} setWindowTitle={setWindowTitle} loginPending={loginPending} setLoginPending={setLoginPending} loggedIn={loggedIn} /> :
              <CircularProgress sx={{ margin: 8 }} />
            }
          </LoginParent>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  )
}

export default App
